.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 200;
  font-size: 14px;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.soulbuddy.popup-root {
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
  background: rgba(0, 0, 0, .8);
}

.soulbuddy.popup-content {
  position: relative;
  margin: auto;
  pointer-events: auto;
  width: 50%;
  padding: 5px;
}

.soulbuddy.popup-selector {
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  color: black;
  border-radius: 15px;
}

.soulbuddy.popup-selector-title {
  cursor: default;
  padding: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 16px;
  /* border-bottom: 1px solid rgb(68, 68, 68); */
}

.soulbuddy.popup-selector-item {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  border-bottom: 1px solid black;
  color: rgb(60, 60, 60);
}

.soulbuddy.popup-selector-item:hover {
  color: #201CE0;
}

.soulbuddy.popup-selector-item.loading:hover {
  color: rgb(90, 90, 90);
  cursor: default;
}